import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { Carousel } from 'react-responsive-carousel';

import styles from '../../styles/info-site/TestimonialsSlider.module.css'

const TestimonialsSlider = ({ items, title }) => {
  const renderCustomPrevArrow = (onClickHandler, hasPrev, label) =>
    hasPrev && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        className={`${styles.arrow} ${styles.arrow_left}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="111" height="75" viewBox="0 0 111 75" fill="none">
          <path d="M1.4983 33.431C-0.472826 35.3649 -0.502975 38.5306 1.43095 40.5017L32.9463 72.6231C34.8802 74.5942 38.0459 74.6244 40.017 72.6904C41.9882 70.7565 42.0183 67.5908 40.0844 65.6197L12.0707 37.0673L40.6231 9.05371C42.5942 7.11978 42.6244 3.9541 40.6904 1.98297C38.7565 0.0118384 35.5908 -0.0183109 33.6197 1.91563L1.4983 33.431ZM110.048 33.0002L5.04762 32.0002L4.95239 41.9998L109.952 42.9998L110.048 33.0002Z" fill="white"/>
        </svg>
      </button>
    );

  const renderCustomNextArrow = (onClickHandler, hasNext, label) =>
    hasNext && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        className={`${styles.arrow} ${styles.arrow_right}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="110" height="75" viewBox="0 0 110 75" fill="none">
          <path d="M108.54 40.8173C110.492 38.8647 110.492 35.6988 108.54 33.7462L76.7198 1.9264C74.7671 -0.0262222 71.6013 -0.0262222 69.6487 1.9264C67.6961 3.87902 67.6961 7.04485 69.6487 8.99747L97.933 37.2817L69.6487 65.566C67.6961 67.5186 67.6961 70.6845 69.6487 72.6371C71.6013 74.5897 74.7671 74.5897 76.7198 72.6371L108.54 40.8173ZM-0.000732422 42.2817H105.004V32.2817H-0.000732422L-0.000732422 42.2817Z" fill="white"/>
        </svg>
      </button>
    );

  return (
    <div className={styles.Root}>
      <div className={styles.Container}>
        <h2 className={styles.Title}><FormattedHTMLMessage id={title} /></h2>
        <div className={styles.wrapper}>
          <Carousel
            // autoPlay
            infiniteLoop
            swipeScollTolerance={40}
            preventMovementUntilSwipeScrollTolerance
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            axis="horizontal"
            renderArrowPrev={renderCustomPrevArrow}
            renderArrowNext={renderCustomNextArrow}
          >
            {
              items.map(item => (
                <div key={item.id} className={styles.testimonial}>
                  <div className={styles.testimonialImage}>
                    <img
                      src={item.img}
                      alt={item.alt}
                    />
                  </div>
                  <div className={styles.testimonialContent}>
                    <img
                      src={item.logo}
                      alt=''
                    />
                    <p><FormattedHTMLMessage id={item.text} /></p>
                    <h3><FormattedHTMLMessage id={item.author} /></h3>
                  </div>
                </div>
              ))
            }
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default TestimonialsSlider