import React from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'


import styles from '../../styles/info-site/KeyBenefits.module.css'

const KeyBenefits = ({ items, title }) => {

  return (
    <div className={styles.Root}>
      <div className={styles.Container}>
        <h2 className={styles.Title}><FormattedHTMLMessage id={title} /></h2>
        {
          items?.length > 0 && (
            <div className={styles.cards}>
              {
                items.map(({ title, desc, img }, key) => (
                  <div key={key} className={styles.card}>
                    <h3><FormattedMessage id={title} /></h3>
                    <div className={styles.imgWrapper}>
                      <img src={img} alt="" />
                    </div>

                    <div className={styles.desc}>
                      <FormattedHTMLMessage id={desc} />
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    </div>
  )
}

export default KeyBenefits