import { Link } from "gatsby";
import React from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import SimpleLogo from "../../assets/simple-logo.png"

import styles from '../../styles/info-site/ScalablePartnerships.module.css'

const ScalablePartnerships = ({ items, title }) => {

  const renderContent = item => {
    switch(item.type) {
      case 'paragraph':
        return <p><FormattedHTMLMessage id={item.value} /></p>
      case 'link':
        return <Link to={item.link}><FormattedHTMLMessage id={item.cta} /></Link>
      case 'subtitle':
        return <h3><FormattedHTMLMessage id={item.value} /></h3>
      case 'list':
        return (
          <div className={styles.list}>
            <FontAwesomeIcon style={{ width: '20px' }} size="xs" icon={faCheckCircle} />
            <FormattedHTMLMessage id={item.value} />
          </div>
        )
      default:
        return null;
    }
  }

  return (
    <div className={styles.Root}>
      <div className={styles.Container}>
        <img className={styles.Logo} src={SimpleLogo} alt='' />

        <h2 className={styles.Title}><FormattedHTMLMessage id={title} /></h2>
        {
          items?.length > 0 && (
            <div className={styles.SideContentWithImg}>
              {
                items.map(({ title, content, reversed, lightParagraph, img }, key) => (
                  <div key={key} className={`${styles.SideContentWithImgWrapper} ${reversed ? styles.SideContentWithImgWrapper__reversed : ''}`}>
                    <div className={`${styles.SideContentWithImgSide} ${styles.SideContentWithImgSide__content} ${lightParagraph ? styles.SideContentWithImgSide__lighter : ''}`}>
                      <h2><FormattedMessage id={title} /></h2>
                      {
                        content?.map(item => renderContent(item))
                      }
                    </div>
                    <div className={styles.SideContentWithImgSide}>
                      <img src={img} alt="" />
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ScalablePartnerships