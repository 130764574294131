import React from "react";
import HomepageStageImage from "../components/info-site/HomepageStageImage";
import Seo from "../components/info-site/Seo";
import Stage from "../components/info-site/Stage";
// import WorksWith from "../components/info-site/WorksWith";
// import FoundersNote from "../components/info-site/FoundersNote";
import GetStarted from "../components/info-site/GetStarted";
import Layout from "../layouts/en";
import Testimonials from "../components/styleguide/Testimonials";
import CompaniesTestimonials from "../components/info-site/CompaniesTestimonials";
// import BiggestChallanges from "../components/info-site/BiggestChallanges";

// // import brandPartnerships from "../data/brandPartnerships";
// import TyvisoSpecial from "../components/info-site/TyvisoSpecial";
// import tyvisoSpecialCards from "../data/tyvisoSpecialCards";
// import BrandPartnerships from "../components/info-site/BrandPartnerships";
import ScalablePartnerships from "../components/info-site/ScalablePartnerships";

import { homepageTestimonials } from "../data/companiesTestimonials";
import scalablePartnerships from "../data/scalablePartnerships";
import keyBenefits from "../data/keyBenefits";
import KeyBenefits from "../components/info-site/KeyBenefits";
import TestimonialsSlider from "../components/info-site/TestimonialsSlider";
import testimonialsSlider from "../data/testimonialsSlider";

const Homepage = props => {
    return (
			<Layout location={props.location}>
				<Seo titleId="homepageTitle" descriptionId="homepageDescription" />
				<Stage
					title="homepageStageTitle"
					primaryCtaLink="/request-demo"
					primaryCtaText="homepageStagePrimaryCta"
          featureList={[
            "featuredHomeOne",
            "featuredHomeTwo",
            "featuredHomeThree",
            "featuredHomeFour",
          ]}
					trustItems={[
						"getStartedTrustItemOne",
            "getStartedTrustItemTwo",
            "getStartedTrustItemThree"
					]}
        >
					<HomepageStageImage />
				</Stage>
				<CompaniesTestimonials
					title="compTestimonialsTitle"
					companies={homepageTestimonials}
				/>
				<ScalablePartnerships
          title="scalablePartnershipsTitle"
          items={scalablePartnerships}
        />
				<KeyBenefits
          title="keyBenefitsTitle"
          items={keyBenefits}
        />
        <TestimonialsSlider
          title="testimonialsSliderTitle"
          items={testimonialsSlider}
        />
				{/* <BiggestChallanges
					title="biggestChallangesTitle"
					subTitle="biggestChallangesSubTitle"
					cards={[
						{
							id: 'cpa',
							link: '/your-goals/acquire-new-customers',
							text: 'biggestChallangesCardOneTitle',
						},
						{
							id: 'ltv',
							link: '/your-goals/raise-customer-lifetime',
							text: 'biggestChallangesCardTwoTitle',
						},
						// {
						// 	id: 'revshare',
						// 	link: '/your-goals/monetise-your-ecommerce',
						// 	text: 'biggestChallangesCardThreeTitle',
						// },
						{
							id: 'cro',
							link: '/your-goals/uplift-basket-completion',
							text: 'biggestChallangesCardFourTitle',
						},
					]}
				/> */}
				{/* <TyvisoSpecial
					title="tyvisoSpecialTitle"
					cards={tyvisoSpecialCards}
				/> */}
				{/* <WorksWith /> */}
				<Testimonials
          title="testimonialTitle"
					partners={[
						'Awin',
						'Tradedoubler',
						'Webgains',
						'Partnerize',
						'CJ',
					]}
				/>
				<GetStarted />
				{/* <FoundersNote /> */}
			</Layout>
		);
};

export default Homepage;