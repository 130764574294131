import TestimonoalImgOne from "../assets/amy-metcalfe-digital-trading-manager-plusnet.jpg"
import TestimonoalImgTwo from "../assets/o4b-toby.jpeg"
import TestimonoalImgThree from "../assets/flora_picture.jpeg"
import TestimonoalImgFour from "../assets/sian.jpeg"
import TestimonoalImgFive from "../assets/secret-sales-3.jpeg"
import LogoOne from "../assets/plusnet.png"
import LogoTwo from "../assets/o4b-logo.png"
import LogoThree from "../assets/wild.png"
import LogoFour from "../assets/p2g-logo-svg.svg"
import LogoFive from "../assets/secret-sales.png"



const testimonialsSlider = [
  {
    id: 'secretsales',
    logo: LogoFive,
    img: TestimonoalImgFive,
    alt: 'Alex Adamson, CMO, SecretSales',
    text: 'testimonialsSliderFiveText',
    author: 'testimonialsSliderFiveOwner'
  },
  {
    id: 'p2go',
    logo: LogoFour,
    img: TestimonoalImgFour,
    alt: 'Sian Wells, Digital Marketing Manager',
    text: 'testimonialsSliderFourText',
    author: 'testimonialsSliderFourOwner'
  },
  {
    id: 'plusnet',
    logo: LogoOne,
    img: TestimonoalImgOne,
    alt: 'Amy Metcalfe Digital Trading Manager Plusnet',
    text: 'testimonialsSliderOneText',
    author: 'testimonialsSliderOneOwner'
  },
  {
    id: 'online4baby',
    logo: LogoTwo,
    img: TestimonoalImgTwo,
    alt: 'Toby Gavin, CMO, Online4Baby',
    text: 'testimonialsSliderTwoText',
    author: 'testimonialsSliderTwoOwner'
  },
  {
    id: 'wild',
    logo: LogoThree,
    img: TestimonoalImgThree,
    alt: 'Flora Jetha, Senior Partnerships Executive, Wild',
    text: 'testimonialsSliderThreeText',
    author: 'testimonialsSliderThreeOwner'
  },
]

export default testimonialsSlider;
