import SetUp from '../assets/vectorial-set-up.png'
import MachineLearning from '../assets/vectorial-machine-learning-driven.png'
import Studio from '../assets/vectorial-tyviso-studio.png'

const keyBenefits = [
  {
    id: "keyBenefits1",
    title: "kBCardTitleOne",
    desc: "kBCardDescOne",
    img: SetUp,
  },
  {
    id: "keyBenefits2",
    title: "kBCardTitleTwo",
    desc: "kBCardDescTwo",
    img: MachineLearning,
  },
  {
    id: "keyBenefits3",
    title: "kBCardTitleThree",
    desc: "kBCardDescThree",
    img: Studio,
  },
]

export default keyBenefits;
