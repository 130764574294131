import PartnersLogoImg from '../assets/partners-logo.png'
import ClaimGiftImg from '../assets/claim-gift.png'
import FreeGift from '../assets/free-gift.png'

const scalablePartnerships = [
  // {
  //   id: "trusted-referral",
  //   title: "brandPartnershipTitleOne",
  //   content: "brandPartnershipDescOne",
  //   ctaText: "brandPartnershipCtaOne",
  //   ctaLink: "/tyviso-recommends",
  //   img: TrustedReferralImg,
  // },
  {
    id: "align-other",
    title: "spOneTitle",
    content: [
      {
        type: 'paragraph',
        value: 'spOneP1'
      },
      {
        type: 'link',
        cta: 'spOneP2',
        link: '/'
      },
    ],
    img: PartnersLogoImg,
    reversed: false,
  },
  {
    id: "help-business",
    title: "spTwoTitle",
    content: [
      {
        type: 'paragraph',
        value: 'spTwoP1'
      },
      {
        type: 'subtitle',
        value: 'spTwoP2',
      },
      {
        type: 'list',
        value: 'spTwoP3',
      },
      {
        type: 'list',
        value: 'spTwoP4',
      },
      {
        type: 'subtitle',
        value: 'spTwoP5',
      },
      {
        type: 'list',
        value: 'spTwoP6',
      },
      {
        type: 'list',
        value: 'spTwoP7',
      },
    ],
    img: FreeGift,
    reversed: true,
  },
  {
    id: "customers-benefit",
    title: "spThreeTitle",
    content: [
      {
        type: 'paragraph',
        value: 'spThreeP1'
      },
      {
        type: 'list',
        value: 'spThreeP2',
      },
      {
        type: 'list',
        value: 'spThreeP3',
      },
      {
        type: 'list',
        value: 'spThreeP4',
      },
      {
        type: 'list',
        value: 'spThreeP5',
      },
      {
        type: 'list',
        value: 'spThreeP6',
      },
    ],
    img: ClaimGiftImg,
    reversed: false,
  },
]

export default scalablePartnerships;
