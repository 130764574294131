import React from "react";
import HomepageStage from "../../assets/homepage-stage-formatted2.png";
import HeroRewardsLibrary from "../../assets/homepage-library.png";
// import Demo from "./Demo";
import styles from "../../styles/info-site/HomepageStageImage.module.css";

const HomepageStageImage = () => {
    return ( 
        <div className={styles.HomepageStageImage}>
            {/* <div className={styles.HomepageStageImageOffersPlaceholder}> */}
                {/* <Demo /> */}
                <img
                  className={styles.HomepageStageRewardsLibrary}
                  src={HeroRewardsLibrary}
                  alt="Regular offers"
                />
            {/* </div> */}
        </div>
    );
};

export default HomepageStageImage;